/* Nav box start */
.nav-box {
    width: 720px;
    height: 360px;
    background: linear-gradient(180deg, #835841 0%, #59473F 48.44%, #3B3C3C 100%);
    padding: 44px 80px 44px 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    transition: all .4s ease-in;
}

.nav-box:hover {
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.692);
    transition: all .3s ease-in-out;
}

.header-box-description {
    /* height: 6rem; */
    overflow: hidden;
}

.nav-box p:first-child {
    cursor: none;
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    color: #FFFFFF;
}

.nav-box p:nth-child(2) {
    cursor: none;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: #FFFFFF;
}

.nav-box button{
    width: 290px;
    height: 60px;
    border: none;
    background: #FFFFFF;
    color: #000000;
    transition: all .3s ease-in-out;
}


.nav-box button a{
    width: 100%;
    display: block;
    /* padding: 20px 111px; */
    border: none;
    background: #FFFFFF;
    font-weight: 900;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
}

.nav-box button:hover {
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.692);
    transition: all .2s ease-in-out;
}

.nav-box button:active {
    background: #ecdbdb;
    box-shadow: 0px 4px 30px rgba(59, 59, 59, 0.575);
}


@media screen and (max-width:921px ) {
    .nav-box{
        width: 80%;
        /* height: 330px; */
    }

    .header-box-description {
        height: 10rem;
        overflow: hidden;
    }

    .nav-box button a {
        font-size: 15px;
        /* padding: 18px 100px; */
    }
}

@media screen and (max-width:770px) {
    .nav-box{
        width: 80%;
        height: 300px;
        padding: 20px;
    }

    .nav-box button a {
        font-size: 14px;
        /* padding: 17px 90px; */
    }
}


@media screen and (max-width:540px) {
    .App {
        height: 100vh!important;
    }

    .App > div {
        height: 300px!important;
    }

    .nav-box{
        width: 100%;
        height: auto;
    }
    .nav-box button{
        height: 45px;
        width: 100%;
        /* padding: 20px 0px; */
    }

    .nav-box p:first-child {
        font-size: 14px;
        line-height: 16px;
    }

    .nav-box button a {
        font-size: 15px;
        /* padding: 14px 70px; */
    }

}

@media screen and (max-width: 375px){
    .nav-box button{
        margin-top: 20px;
    }
}