.audit-icon img{
    width: 70px;
}

.audit-container{
    max-width: 650px;
    width: 100%;
}

.audit-inner{
    position: relative;
    display: flex;
    align-items: center;
    background-color: #f7f7f7;
    padding: 30px;
    margin-bottom: 25px;
    gap: 10px;
}


.audit-text h3{
    font-size: 16px;
    margin-bottom: 0;
}

.audit-text-url h3{
    font-size: 16px;
    margin-bottom: 0;
}

.audit-text-url:hover h3 {
    text-decoration: underline;
}


.audit-text a{
    text-align: center;
    display: block;
    font-size: 14px;
    line-height: 20px;
    text-decoration: underline !important;
    color: #A8A8A8;
    display: table;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    bottom: 50%;
    left: 0;
    right: 0;
    transform: translate(50%, -50%);
}

.audit-text a:hover{
    color: #A8A8A8;
}

@media screen  and (max-width: 425px){
    .audit-text h3{
        font-size: 14px;
    }

    .audit-icon img{
        width: 50px;
    }

    .audit-inner{
        padding: 20px;
    }
}

@media screen  and (max-width: 375px){
    .audit-text h3{
        font-size: 12px;
    }
}