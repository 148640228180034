.contact-container {
    max-width: 940px !important;
    width: 100%;
    /* background-color: aquamarine; */
}

.contact-row {
    margin-top: 39px !important;
}

.corrupt{
    margin-top: 15px;
    background: linear-gradient(167.03deg, #46403D -0.44%, #805741 100.47%);
    border-radius: 10px;
    padding: 15px;
}


.corrupt p{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #fff;
    margin-bottom: 0;
}

.corrupt a{
    color: #fff;
    text-decoration: underline !important;
    margin: 10px 0;
    display: block;
    font-size: 20px;
}

.corrupt a:hover{
    color: #fff;
}
.contact-h1 {
    font-family: Noto-Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    color: #fff;
    margin-bottom: 10px;
}

.contact-details {
    font-family: Noto-Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #fff;
    /* margin-left: 20px; */
    margin-bottom: 10px!important;
}

.contact-img{
    width: 14px;
}

/* .social-link {
    margin-top: 50px;
} */

.social-link ul {
    display: flex;
    gap: 16px;
    padding-left: 0;
}

.social-link ul li a {
    width: 36px;
    height: 36px;
    background: #2C2C2C;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
}

.social-link ul li a:hover {
    opacity: 0.8;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.contact-form label {
    font-family: Noto-Sans;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* align-items: center; */
    justify-content: center;
    color: #333333;
    margin-bottom: 5px;

}

.contact-form input {
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.075);
    border-radius: 4px;
    padding: 0.5rem 0.75rem;
}

/* .form-group label {
    margin-top: 10px;
} */

button.contact-send {
    font-family: Noto-Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    background: #825841;
    border-radius: 4px;
}

.contact-map {
    margin-top: 37px !important;
}

.mtb {
    margin-top: -15px;
    margin-bottom: 15px;
}

input.input-error,
textarea.input-error {
    color: #212529;
    background-color: #fff;
    border-color: #dc7474;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(252, 129, 129, .25);
}

input.input-error:focus,
textarea.input-error:focus {
    color: #212529;
    background-color: #fff;
    border-color: #fc8181;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(252, 129, 129, .25);
}

.error {
    color: #fe6e6e;
    font-size: 14px;
    margin-top: 5px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.contact-left-information {
    width: 100%;
    background: linear-gradient(167.03deg, #46403D -0.44%, #805741 100.47%);
    border-radius: 10px;
    padding: 19px 10px 5px 27px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}


._loading_overlay_wrapper {
    width: 100%;
    height: 100vh;
    position: fixed!important;
    top: 0!important;
    background-color: rgba(0,0,0,0.1);
    z-index: 1000;
}

