.home-news-row {
    display: flex;
    justify-content: space-between;
}

.home-news-row>h2 {
    font-weight: 800;
    font-size: 20px;
    line-height: 27px;
    color: #FFFFFF;
}

 img.img-three{
    max-width: 300px;
    width: 100%;
    height: 200px;
}

.home-news-row>a>button {
    background-color: transparent;
    border: 1px solid #FFFFFF;
    border-radius: 5px;
    font-weight: 800;
    font-size: 10px;
    line-height: 14px;
    color: #FFFFFF;
    padding: 7px 12px;
}

.home-news-row>a>button>img {
    margin-right: 10px;
    /* margin-bottom: 10px; */
}

.news-section-boxs {
    display: flex;
    margin-top: 31px;
    border-bottom: 1px solid #D9D9D9;
    padding-bottom: 21px;
    flex-wrap: wrap;
    margin-top: 20px!important;
}

.home-page-news-box {
    width: 100%;
    margin-right: 20px;
}

.home-page-news-box button {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 18px;
    border: 1px solid #E9D3D3;
    border-radius: 5px;
    font-weight: 800;
    font-size: 10px;
    color: #FFFFFF;
    background: transparent;
    padding: 10px 5px;
    position: absolute;
    left: 14px;
    bottom: 14px;
}

.news-box-content {
    max-width: 300px;
    width: 100%;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
}

.box-img {
    position: relative;
    margin-bottom: 10px;
}

.px-custom-0{
    padding-left: 0 ;
}

.home-page-news-link {
    max-width: 300px;
    width: 100%;
}

@media screen and (max-width:990px) and (min-width:682px) {
    .news-section-boxs{
        flex-wrap: wrap;
    }
    .px-custom-0{
        padding-right: 3%;
    }
}

@media screen and (max-width:681px){
    .news-section-boxs{
        flex-direction: column;
        align-items: center;
    }
}

@media screen and (max-width:540px) {
    
    .home-page-news-box{
        margin-right: 0;

    }

    .box-img img, .news-box-content{
        width: 100%;
    }
}

@media screen and (max-width: 991px)
{
    .box-img img{
        width: 100%;
    }
}