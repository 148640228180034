.careers {
  width: 100%;
  background-color: #fff;
}

.careers-container {
  max-width: 940px !important;
  width: 100%;
  padding-top: 35px;
}

.career-content {
  margin-top: 30px;
}

.career-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 46px 0;
}

a.career-item-link1,a.career-item-link2{
  width: 300px;
}

.career-image1,.career-image2 {
  width: 300px;
  height: 300px;
  background: #FFFFFF;
  box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-right: 0 !important;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
}


.link-p{
  font-family: Noto-Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 49px;
  text-align: right;
  color: #3B3C3C;
}


@media screen and (max-width: 650px) {
  .career-item-link1 , .career-item-link2{
    padding-left: 0 !important;
  }
}


@media screen and (max-width: 600px) {
  .career-item-link1 , .career-item-link2{
    padding-left: 0 !important;
  }

  .career-items{
    justify-content: center;
    gap: 20px;
  }
}