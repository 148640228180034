.App {
  position: relative;
  background-image: url(https://mrd.butagrup.az/upload/headerbg.jpg);
  background-size: cover;
  background-position: center;
  height: 885px;
}

.heading-all {
  height: 350px;
  /* background: linear-gradient(180deg,
            rgba(0, 0, 0, 0.7) 0%,
            rgba(0, 0, 0, 0) 49.48%,
            rgba(0, 0, 0, 0.7) 100%),
        url('../../img/heading-all.png'); */
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  position: relative;
}

.header-bg-respon {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heading-title {
  font-family: Noto-Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 46px;
  line-height: 63px;
  color: #ffffff;
  position: absolute;
  bottom: 30px;
}

.heading-all-container {
  max-width: 940px !important;
  width: 100%;
}

.section-bg {
  position: relative;
  background: #45403d;
  padding: 95px 0;
}

.section-news-bg {
  padding: 40px 0 0 0;
  background: linear-gradient(180deg, #825841 0%, #57473f 51.04%, #181918 100%);
}

.section-footer-bg {
  background: #181a18;
  padding: 44px 0;
  position: relative;
}

.footer-bg {
  background: #181918 !important;
}

.custom-container {
  max-width: 940px !important;
  width: 100%;
  padding: 0 30px;
}

.pd-mine {
  padding: 0 0 29px 0 !important;
}

.mine-container {
  max-width: 100% !important;
  width: 100%;
}

@media screen and (max-width: 921px) {
  .App {
    height: 900px;
  }
}

.loader-overlayy {
  position: fixed;
  top: 0;
  background: linear-gradient(180deg, #835841 0%, #59473f 48.44%, #3b3c3c 100%);
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999 !important;
}

.loader-overlayy img {
  width: 150px;
  height: 150px;
  border-radius: 10px;
}
