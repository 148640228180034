.not-page-flex {
    height: 84vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.not-app {
    height: 100vh;
}

.not-found {
    width: 869px;
    height: 466px;
    background: rgba(217, 217, 217, 0.2);
    backdrop-filter: blur(15px);
    border-radius: 94px;
    padding: 43px 68px;
    /* margin-left: 30px; */
    /* margin-bottom: 4%; */
    text-align: center;
    border: 1px  solid #e9e9e9;
}



.err-status,
.err-desc {
    font-family: Quantico;
    font-style: normal;
    font-weight: 700;
    font-size: 128px;
    line-height: 183px;
    color: #FFFFFF;
    text-shadow: 0px 12px 4px rgba(0, 0, 0, 0.65);
}



@media screen and (max-width:990px) {
    .not-found {
        width: 90%;
    }

    .err-status,
    .err-desc {
        font-size: 100px;
    }
}

@media screen and (max-width:730px) {
    .not-found {
        height: 400px;
        /* margin-bottom: 5%; */
    }

    .err-status,
    .err-desc {
        font-size: 80px;
        line-height: 140px;
    }
}

@media screen and (max-width:617px) {
    .not-found {
        height: 300px;
        /* margin-bottom: 5%; */
        border-radius: 70px;
    }

    .err-status,
    .err-desc {
        font-size: 60px;
        line-height: 120px;
    }
}

@media screen and (max-width:506px) {
    .not-found {
        border-radius: 50px;
        /* margin-bottom: 30%; */

    }

    .err-status,
    .err-desc {
        line-height: 60px;
    }
}

@media screen and (max-width:370px) {
    .not-found {
        width: 80%;
        border-radius: 30px;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .err-status,
    .err-desc {
        line-height: 60px;
    }
}